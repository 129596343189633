import styled from "styled-components"

export const options = {
  black: "#000",
  white: "#fff",
  turquoise: "#00A2B1",
  backgroundTransparent: "rgba(20, 18, 18, 0.5)",
  backgroundColor: "#191919",
  filter: "backdrop-filter: blur(20px);",
  fontFamily: '"Montserrat", sans-serif',
}

export const H1 = styled.h1`
  font-size: 100px;
  font-weight: bold;
  color: ${options.white};
  font-family: ${options.fontFamily};
  margin: 0;
  letter-spacing: 0.012em;
  @media only screen and (max-width: 1300px) {
    font-size: 80px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 40px;
  }
`

export const H1xl = styled.h1`
  font-size: 164px;
  font-weight: bold;
  color: ${options.white};
  font-family: ${options.fontFamily};
  margin: 0;
  letter-spacing: 0.012em;
  @media only screen and (max-width: 1825px) {
    font-size: 130px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 100px;
  }
  @media screen and (max-width: 700px) {
    font-size: 60px;
  }
  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
`

export const H1t = styled.h1`
  font-size: 100px;
  font-weight: bold;
  color: ${options.turquoise};
  font-family: ${options.fontFamily};
  margin: 0;
  letter-spacing: 0.012em;
  @media only screen and (max-width: 1800px) {
    font-size: 80px;
  }
`

export const H2 = styled.h1`
  font-size: 50px;
  color: ${options.white};
  font-family: ${options.fontFamily};
  margin-bottom: 100px;
  @media screen and (max-width: 1200px) {
    text-align: center;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 550px) {
    font-size: 30px;
    text-align: center;
  }
`

export const H3 = styled.h1`
  font-size: 25px;
  color: ${options.turquoise};
  font-family: ${options.fontFamily};
`

export const H4 = styled.h1`
  font-size: 20px;
  color: ${options.turquoise};
  font-family: ${options.fontFamily};
`

export const P = styled.p`
  font-size: 16px;
  color: ${options.white};
  font-family: ${options.fontFamily};
`

export const Pt = styled.p`
  font-size: 16px;
  color: ${options.turquoise};
  font-family: ${options.fontFamily};
`

export const Ps = styled.p`
  font-size: 15px;
  color: ${options.white};
  font-family: ${options.fontFamily};
`

export const At = styled.p`
  font-size: 20px;
  color: ${options.turquoise};
  font-family: ${options.fontFamily};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  margin: 0 0 50px 0;
`

export const A = styled.p`
  font-size: 20px;
  color: ${options.white};
  font-family: ${options.fontFamily};
  font-weight: normal;
  /* opacity: 0.5; */
  cursor: pointer;
  white-space: nowrap;
  margin: 0 0 50px 0;
`

export const Al = styled.p`
  font-size: 20px;
  color: ${options.white};
  font-family: ${options.fontFamily};
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
`

export const TreningImage = styled.img`
  width: 100vw;
  min-width: 1920px;
  height: 1200px;
`

export const HomeImage = styled.img`
  width: 100vw;
  min-width: 1920px;
  height: 1200px;
  margin: 0 !important;
  position: fixed;
  float: right;
  filter: grayscale(50%) contrast(30%) brightness(40%);
  @media screen and (min-width: 1920px) {
    height: auto;
  }
  @media screen and (min-height: 1200px) {
    width: auto;
    height: 100vh;
  }
  @media screen and (min-height: 550px) {
    width: 100%;
    height: auto;
  }
`
