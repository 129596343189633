import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { P } from "../components/theme"
import styled from "styled-components"
import LogoBig from "../assets/logoBig.svg"
import "./hamburger.css"
import Face from "../assets/face.svg"
import Insta from "../assets/insta.svg"

const HeaderContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 170px;
  position: absolute;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 800px) {
    height: 100px;
  }
`

const LogoSections = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 100px;
    min-width: auto;
    display: block;
  }
`

const BtnSection = styled.div`
  display: none;
  width: 300px;
  height: 100%;

  flex-direction: row;
  justify-content: center;
  align-self: center;
  background: #191919;
  position: relative;
  @media screen and (max-width: 1200px) {
    display: flex;
  }
  @media screen and (max-width: 800px) {
    width: 100px;
  }
`

const MenuTest = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 600px);
  a {
    width: 25%;
    text-align: center;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const Social = styled.div`
  width: 300px;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgb(0, 162, 177, 0.5);
  a {
    padding-left: 20%;
    padding-right: 20%;
    cursor: pointer;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const SocialRes = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a {
    cursor: pointer;
    svg {
      path {
        fill: black;
      }
    }
  }
`

const LogoStyle = {
  width: "138",
  padding: "5px",
}

class Header extends Component {
  constructor(siteTitle) {
    super(siteTitle)
    this.menuStatus = this.menuStatus.bind(this)

    this.state = {
      menuOpened: false,
      myPath: null,
    }
  }

  menuStatus = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }

  componentDidMount = () => {
    console.log(window.location.pathname)
    this.setState({
      myPath: window.location.pathname,
    })
  }

  render() {
    return (
      <>
        <HeaderContainer>
          <LogoSections>
            <Link to="/">
              <LogoBig style={LogoStyle} />
            </Link>
          </LogoSections>

          <MenuTest>
            <Link className="links" activeClassName="links--active" to="/Zira">
              Fast Fit Zira
            </Link>
            <Link className="links" activeClassName="links--active" to="/Plaza">
              Fit Plaza
            </Link>
            <Link
              className="links"
              activeClassName="links--active"
              to="/Mihabodytec"
            >
              EMS Tehnologija
            </Link>
            <Link
              className="links"
              activeClassName="links--active"
              to="/Kontakt"
            >
              Kontakt
            </Link>
          </MenuTest>
          <Social>
            {this.state.myPath === "/Plaza" ? (
              <a
                href="https://www.facebook.com/Fit-Plaza-110598477397165"
                alt=""
              >
                <Face />
              </a>
            ) : (
              <a href="https://www.facebook.com/fastfitrs/" alt="">
                <Face />
              </a>
            )}

            {this.state.myPath === "/Plaza" ? (
              <a href="https://www.instagram.com/fit.plaza/" alt="">
                <Insta />
              </a>
            ) : (
              <a href="https://www.instagram.com/fastfit.rs/" alt="">
                <Insta />
              </a>
            )}
          </Social>

          <BtnSection>
            <button
              className={
                this.state.menuOpened === false
                  ? "hamburger hamburger--collapse"
                  : "hamburger hamburger--collapse is-active"
              }
              onClick={this.menuStatus}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </BtnSection>
        </HeaderContainer>

        <div
          className={this.state.menuOpened === false ? "menu" : "menu opened"}
        >
          <Link
            className="linksRes"
            activeClassName="linksRes--active"
            to="/Zira"
          >
            <P>Fast Fit Zira</P>
          </Link>
          <Link
            className="linksRes"
            activeClassName="linksRes--active"
            to="/Plaza"
          >
            <P>Fit Plaza</P>
          </Link>
          <Link
            className="linksRes"
            activeClassName="links--active"
            to="/Mihabodytec"
          >
            <P>EMS Tehnologija</P>
          </Link>
          <Link
            className="linksRes"
            activeClassName="linksRes--active"
            to="/Kontakt"
          >
            <P>Kontakt</P>
          </Link>

          <SocialRes>
            {this.state.myPath === "/Plaza" ? (
              <a
                href="https://www.facebook.com/Fit-Plaza-110598477397165"
                alt=""
              >
                <Face />
              </a>
            ) : (
              <a href="https://www.facebook.com/fastfitrs/" alt="">
                <Face />
              </a>
            )}

            {this.state.myPath === "/Plaza" ? (
              <a href="https://www.instagram.com/fit.plaza/" alt="">
                <Insta />
              </a>
            ) : (
              <a href="https://www.instagram.com/fastfit.rs/" alt="">
                <Insta />
              </a>
            )}
          </SocialRes>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
